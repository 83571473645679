<template>
  <div class="home">
    <van-button type="primary" @click="$router.push('invitationRegisterpage')">
      邀请注册页面</van-button>
    <van-button type="primary" @click="$router.push('invitationRegister')">
      邀请注册</van-button>
    <van-button type="primary" @click="$router.push('makeMoney')">
      走路赚钱</van-button>
    <van-button type="primary" @click="$router.push('memberOrder')">
      会员等级</van-button>
    <van-button type="primary" @click="$router.push('shareVoucher')">
      卡券分享</van-button>
    <van-button type="primary" @click="$router.push('privacyPolicy')"> 隐私政策</van-button>
    <van-button type="primary" @click="$router.push('userAgreement')"> 用户协议</van-button>
    <van-button type="primary" @click="$router.push('Goodsclassify')">
      商品分类</van-button>
    <van-button type="primary" @click="$router.push('Golddata')">黄金数据</van-button>
    <van-button type="primary" @click="$router.push('Constellation')">星座</van-button>
    <van-button type="primary" @click="$router.push('Weatherforecast')">天气预报</van-button>
    <van-button type="primary" @click="$router.push('Signin')">签到</van-button>
    <van-button type="primary" @click="$router.push('Collect')">我的收藏</van-button>
    <van-button type="danger" @click="$router.push('orderManagement')">订单管理</van-button>
    <van-button type="danger" @click="$router.push('fansManagementNew')">粉丝订单</van-button>

    <van-button type="primary" @click="$router.push('Commentshop')">店铺评价</van-button>
    <van-button type="primary" @click="$router.push('goodshare')">商品分享</van-button>
    <van-button type="primary" @click="$router.push('Membership')">会员升级</van-button>
    <van-button type="primary" @click="$router.push('Membership_one')">会员奖励升级办法</van-button>
    <!-- <van-button type="primary" @click="$router.push('Check')">核验订单</van-button> -->
    <van-button type="primary" @click="$router.push('Merchant')">商家管理中心</van-button>
    <van-button type="primary" @click="$router.push('commodity')">商品管理</van-button>
    <van-button type="primary" @click="$router.push('placeOrder')">买卖提交订单</van-button>
    <van-button type="primary" @click="$router.push('lifeDetails')">生活详情</van-button>
    <!-- <van-button type="primary" @click="$router.push('Settinger')">小店设置</van-button> -->
    <van-button type="primary" @click="$router.push('Purchase')">抵用券选择</van-button>
    <van-button type="primary" @click="$router.push('MyShop')">我的小店</van-button>
    <!-- <van-button type="primary" @click="$router.push('WoShopone')">我的小店壹</van-button>   -->
    <van-button type="primary" @click="$router.push('commodder')">订单管理壹</van-button>
    <van-button type="primary" @click="$router.push('platformData')">平台数据</van-button>
    <van-button type="primary" @click="$router.push('dataStatisticsLogin')">数据统计PC</van-button>
    <van-button type="danger" @click="$router.push('demo')">邻劵中心（个人中心）</van-button>
    <van-button type="danger" @click="$router.push('order')">订单详情-待发货（个人中心）</van-button>
    <van-button type="danger" @click="$router.push('orders')">订单详情-待付款（个人中心）</van-button>
    <van-button type="danger" @click="$router.push('people')">邀请好友（个人中心）</van-button>
    <van-button type="danger" @click="$router.push('shippingAddress')">收货地址1 （自营商城</van-button>
    <van-button type="danger" @click="$router.push('shippingAddressthree')">收货地址3 （自营商城</van-button>
    <van-button type="danger" @click="$router.push('shippingAddresstwo')">收货地址2 （自营商城</van-button>
    <van-button type="danger" @click="$router.push('shippingAddressfive')">收货地址5 （自营商城</van-button>
    <van-button type="danger" @click="$router.push('detailsgoods')">商品详情（自营商城）</van-button>
    <!-- <van-button type="danger" @click="$router.push('shelves')">上架到小店 （自营商城)</van-button> -->
    <van-button type="danger" @click="$router.push('choose')">选择样式 （自营商城)</van-button>
    <van-button type="danger" @click="$router.push('classification')">分类 （自营商城)</van-button>
    <van-button type="danger" @click="$router.push('detailsPage')">店铺详情页（自营商城)</van-button>
    <van-button type="danger" @click="$router.push('shoppingcart')">购物车（自营商城)</van-button>
    <van-button type="danger" @click="$router.push('sureorder')">确认订单（自营商城)
    </van-button>
    <van-button type="danger" @click="$router.push('coupon')">领取优惠卷（自营商城)</van-button>
    <van-button type="danger" @click="$router.push('AhotelDetails')">酒店首页</van-button>
    <van-button type="danger" @click="$router.push('commoditySpike')">秒杀</van-button>
    <van-button type="danger" @click="$router.push('SpellGroup')">拼团</van-button>
    <van-button type="danger" @click="$router.push('Bargain')">砍价</van-button>
    <van-button type="danger" @click="$router.push('vehicleDetails')">二手车</van-button>
    <van-button type="danger" @click="$router.push('listingDetails')">二手房</van-button>
    <van-button type="danger" @click="$router.push('Driving')">驾考</van-button>
    <van-button type="danger" @click="$router.push('/calendar')">万年历</van-button>
    <van-button type="danger" @click="$router.push('ordersCenter')">订单页</van-button>
    <van-button type="danger" @click="$router.push('refund')">退款</van-button>
    <van-button type="danger" @click="$router.push('collection')">收藏中心</van-button>
    <!-- <van-button type="danger" @click="$router.push('movieDetail')">电影订单详情</van-button> -->
    <!-- <van-button type="danger" @click="$router.push('fitment')">店铺装修</van-button> -->
    <!-- <van-button type="danger" @click="$router.push('maker')">创客中心</van-button> -->
    <van-button type="danger" @click="$router.push('evaluate')">商品评价</van-button>
    <!-- <van-button type="danger" @click="$router.push('makerOrder')">创客订单</van-button> -->
    <!-- <van-button type="danger" @click="$router.push('makerorderDetail')">创客订单详情</van-button> -->
    <!-- <van-button type="danger" @click="$router.push('makervip')">创客会员</van-button> -->
    <van-button type="danger" @click="$router.push('upOrder')">提交订单</van-button>
    <van-button type="danger" @click="$router.push('advertisingdetails')">图文广告详情</van-button>

    <van-button type="danger" @click="$router.push('/verify')">验证</van-button>
    <van-button type="danger" @click="$router.push('/markerGameDetail')">试玩详情</van-button>
    <van-button type="danger" @click="$router.push('/markerGameDownLoad')">截图试玩详情</van-button>
    <van-button type="danger" @click="$router.push('/keepOrder')">家政订单详情</van-button>
    <van-button type="danger" @click="$router.push('/makerAfterSale')">创客售后列表</van-button>

    <van-button type="danger" @click="$router.push('/logistics')">物流信息</van-button>

    <van-button type="danger" @click="$router.push('/printscreen')">截图</van-button>
    <!-- <van-button type="danger" @click="$router.push('/takeOutOrderDetail')">外卖订单详情</van-button> -->
    <van-button type="danger" @click="$router.push('/mallRefund')">自营商城退款</van-button>

    <!-- <van-button type="danger" @click="$router.push('/adForm')">图文表单</van-button> -->
    <van-button type="danger" @click="$router.push('/goodsdetails')">淘京拼商品详情</van-button>
    <van-button type="danger" @click="$router.push('/sign')">签到</van-button>
    <van-button type="danger" @click="$router.push('/itemDetail')">商品详情</van-button>
    <van-button type="danger" @click="$router.push('/shopDetail')">店铺详情页</van-button>
    <van-button type="danger" @click="$router.push('/cart2')">购物车</van-button>
    <van-button type="danger" @click="$router.push('/applySale')">申请售后</van-button>
    <van-button type="danger" @click="$router.push('/returnMoneyDetail')">退款详情</van-button>
    <van-button type="danger" @click="$router.push('/returnGoodsDetail')">退货详情</van-button>
    <van-button type="danger" @click="$router.push('/preventEpi')">防疫指南</van-button>
    <van-button type="danger" @click="$router.push('/solveDream')">周公解梦</van-button>
    <van-button type="danger" @click="$router.push('/comment')">我的评论</van-button>
    <van-button type="danger" @click="$router.push('/news')">新闻</van-button>
    <van-button type="danger" @click="$router.push('/library')">图书电商</van-button>
    <van-button type="danger" @click="$router.push('/login')">登录注册</van-button>
    <van-button type="danger" @click="$router.push('/birthday')">生日花语</van-button>

    <van-button type="danger" @click="$router.push('/detail')">商品详情</van-button>

    <van-button type="danger" @click="$router.push('/letter')">确认订单</van-button>

    <van-button type="danger" @click="$router.push('/items')">订单详情(待发货)</van-button>
    <van-button type="primary" @click="$router.push('/orderIdDetail')">订单详情(单个订单详情)</van-button>
    <van-button type="primary" @click="$router.push('/confirmReceipt')">确认收货</van-button>
    <van-button type="primary" @click="$router.push('/applyForChange')">申请换货（从订单详情跳转）</van-button>
    <van-button type="primary" @click="$router.push('/changeGoodsDetail')">换货详情</van-button>
    <van-button type="primary" @click="$router.push('/goodsEvaluate')">评价页面(订单详情跳转)</van-button>
    <van-button type="danger" @click="$router.push('/writeLogistics')">填写物流(换货详情跳转)</van-button>

    <van-button type="danger" @click="$router.push('/success')">订单详情(交易成功)</van-button>

    <van-button type="danger" @click="$router.push('/reception')">订单详情(待收货)</van-button>
    <van-button type="danger" @click="$router.push('/lianghao')">靓号管理</van-button>
    <van-button type="danger" @click="$router.push('/lianghaoinfo')">交易记录</van-button>
    <van-button type="danger" @click="$router.push('/lianghaoPay')">靓号支付</van-button>
    <!-- vehicleDetails?id=1462987324658995201&session=140aa308-eab4-4f7f-98de-9bb22e207c0c -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: null
    };
  },
  methods: {
    // enterShop() {
    //     this.$router.push({
    //       path: '/shopDetail',
    //     })
    // }
  },
  created() {
    // process.env.NODE_ENV === "production"
    //   ? (window.location.href = "https://mall.ydcp315.com/pc/")
    //   : null;
  },
  mounted() { },
  watch: {},
  computed: {}
};
</script>
